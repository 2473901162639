define('matrix-frontend/utils/state', ['exports', 'matrix-frontend/store', 'npm:sscopes'], function (exports, _store, _npmSscopes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getClientOption = exports.getCubeLocation = exports.isFieldAdmin = exports.hasScope = exports.hasRole = undefined;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var IMPLIED_ROLES = {
        schedule3: ['schedule3'],
        schedule2: ['schedule2', 'schedule3'],
        schedule1: ['schedule1', 'schedule2', 'schedule3']
    };

    var hasRole = function hasRole(role) {
        var implied = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var currentUser = _store.store.getState().user.currentUser;

        if (currentUser && currentUser.roles) {
            return implied ? currentUser.roles.filter(function (x) {
                return IMPLIED_ROLES[role].includes(x.name);
            }).length > 0 : currentUser.roles.filter(function (x) {
                return x.name === role;
            }).length > 0;
        }
        return false;
    };

    var hasScope = function hasScope(base) {
        if (hasRole('matrix_admin')) {
            var override = window.localStorage[base];
            if (override != null) return override == 'true';
            return true;
        }

        var currentUser = _store.store.getState().user.currentUser;

        var clientCode = _store.store.getState().cube.clientCode;

        var clients = _store.store.getState().client.clients;

        var currentClient = clients[clientCode];
        // console.info(currentUser, clientCode, clients)
        if (!currentClient) {
            currentClient = _store.store.getState().client[clientCode];
        }
        if (currentClient && currentUser && currentUser.roles) {
            var userRoleNames = currentUser.roles.map(function (x) {
                return x.name;
            });
            var roles = currentClient.roles.filter(function (x) {
                return userRoleNames.includes(x.name);
            });
            var scopes = roles.reduce(function (o, i) {
                return [].concat(_toConsumableArray(o), _toConsumableArray(i.scopes));
            }, []);
            return _npmSscopes.default.validate(base, scopes);
        }
        return false;
    };

    var isFieldAdmin = function isFieldAdmin(_) {
        var currentUser = _store.store.getState().user.currentUser;

        if (currentUser && currentUser.roles) {
            return hasRole('matrix_admin') || hasRole('field_admin');
        }
        return false;
    };

    // TODO
    // early system for language labels with options
    var LANG_LABEL_HANDLERS = {
        forecast1_label: {
            default: function _default(_ref) {
                var split = _ref.split;

                return split ? ['Original', 'Forecast'] : 'Original Forecast';
            },
            lb: function lb(_ref2) {
                var short = _ref2.short,
                    split = _ref2.split;

                if (short) return 'Sch Fcst.';
                return split ? ['Schedule', 'Forecast'] : 'Schedule Forecast';
            },
            jjill: function jjill(_ref3) {
                var short = _ref3.short,
                    split = _ref3.split;

                if (short) return 'Ops Goal';
                return split ? ['Store', 'Ops Goal'] : 'Store Ops Goal';
            }
        },
        forecast3_label: {
            default: function _default(_ref4) {
                var short = _ref4.short,
                    target = _ref4.target;

                if (short) {
                    return target === 'pw_detail' ? 'Current' : target === 'pw_summary' ? 'Cur. Fcst' : null;
                }
                return 'Current Forecast';
            }
        },
        unknown: function unknown(opts) {
            return '<UNKNOWN LABEL>';
        }
    };

    var optionParsers = {
        default: function _default(_, x) {
            return x;
        },
        bool: function bool(_, x) {
            return x === 'T' ? true : x === 'F' ? false : null;
        },
        label: function label(code, x, opts) {
            return ((LANG_LABEL_HANDLERS[code] || {})[x] || LANG_LABEL_HANDLERS.unknown)(opts || {});
        },
        int: function int(_, x) {
            return parseInt(x);
        }
    };

    var getClientOption = function getClientOption(optionCode) {
        var parseFuncKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
        var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        var clientCode = _store.store.getState().cube.clientCode;

        var clients = _store.store.getState().client.clients;

        var currentClient = clients[clientCode];

        var parseFunc = optionParsers[parseFuncKey];
        if (currentClient && currentClient.options && currentClient.options[optionCode] && parseFunc) {
            var o = currentClient.options[optionCode];
            return parseFunc(optionCode, o.value, opts);
        }

        return null;
    };

    var getCubeLocation = function getCubeLocation(_) {
        try {
            var _store$getState = _store.store.getState(),
                _store$getState$cube = _store$getState.cube,
                targetName = _store$getState$cube.targetName,
                _store$getState$cube$ = _store$getState$cube.node,
                clientCode = _store$getState$cube$.clientCode,
                tag = _store$getState$cube$.tag,
                weekEndDate = _store$getState$cube.weekEndDate;

            var cubeLocation = [targetName, weekEndDate.format('YYYY-MM-DD'), clientCode + '.' + tag];
            return cubeLocation.join(':');
        } catch (e) {
            return null;
        }
    };

    exports.hasRole = hasRole;
    exports.hasScope = hasScope;
    exports.isFieldAdmin = isFieldAdmin;
    exports.getCubeLocation = getCubeLocation;
    exports.getClientOption = getClientOption;
});